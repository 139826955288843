<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import {
    dateAndTime,
    shortDate,
    isImagingSequence,
  } from "../helpers/formatters";
  import { renderFullName, getBadgeClassName } from "./helpers";
  import { compute_comments } from "../viewer-module/comments-panel.svelte";

  // External Props.
  export let exam = null;

  // Links
  $: href = `#/exams/details/${exam.examId}`;
  $: openAllHref =
    exam && exam.seriesSet && exam.seriesSet.length
      ? `#/multiviewer?scans=${exam.seriesSet
          .filter(scan => isImagingSequence(scan))
          .map(({ seriesId }) => seriesId)
          .toString()}`
      : false;

  // Comments
  $: comments = compute_comments(exam ? exam.seriesSet : false) || [];
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

:global(.exam-list-scan-badge) {
  border: 1px solid #80417d; }

.exam-list-item {
  position: relative;
  display: block;
  border: 1px solid #000;
  padding: 0.5rem 0 0;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  color: inherit;
  transition: border-top-color 0.2s ease-out; }
  .exam-list-item:hover {
    color: inherit;
    text-decoration: none;
    border-top-color: #4daf7c !important; }

.row-val {
  font-size: 80%;
  color: #6c757d; }
  @media (min-width: 768px) {
    .row-val {
      font-size: inherit;
      color: inherit; } }

@media (min-width: 576px) {
  .open-all-btn {
    position: relative !important; } }

.pt-little {
  margin-bottom: -4px; }
  @media (min-width: 768px) {
    .pt-little {
      padding-top: 2px;
      margin-bottom: 0; } }

.summary-header {
  margin-bottom: 9px; }

/*# sourceMappingURL=x.map */</style>

{#if exam}
  <a class="exam-list-item" data-component="exam-list-item" {href}>
    <div class="container-fluid">
      <div class="form-row">

        <!-- Patient Info - Name, Date of Birth, & MRN -->
        <div class="col-12 col-sm-5 col-md-6 col-lg-5 col-xxl-3">
          <div class="row">
            <!-- Patient Name -->
            <h5
              class="col-12 col-md-7 text-truncate mb-1"
              class:font-italic={!exam.subject || !exam.subject.fullName}>
              {renderFullName(exam.subject ? exam.subject.fullName : '')}
            </h5>

            <!-- MRN (Medical Records Number) -->
            <div class="col-md-5 text-truncate pt-little mb-0">
              <span class="small text-muted letter-space-1">
                {translate('exam_data.medical_records_number_short')}
              </span>
              <span class="small text-muted d-md-none">:</span>
              <br class="d-none d-md-block" />
              <span
                class="row-val"
                class:font-italic={!exam.subject || !exam.subject.subjectId}>
                {exam.subject && exam.subject.subjectId ? exam.subject.subjectId : translate('unknown')}
              </span>
            </div>

            <!-- Date of Birth -->
            <div
              class="col-12 col-md-6 text-truncate small text-muted mt-md--4
              pt-md-1">
              <span class="letter-space-1">
                {translate('patient_data.dob', 1)} :
              </span>
              <span
                class:font-italic={!exam.subject || !exam.subject.birthDate}>
                {shortDate(exam.subject ? exam.subject.birthDate : null) || translate('patient_data.dob_unknown')}
              </span>
            </div>
          </div>
        </div>

        <!-- Exam Info - Scanner, Scans, Time, etc -->
        <div class="col-sm-7 col-md">
          <div class="row">

            <!-- Summary Header (card-view only) -->
            <div class="col-12 d-md-none">
              <h6 class="summary-header mt-3 mt-sm-0">
                {translate('exam_data.summary')}
              </h6>
            </div>

            <!-- Conducted At -->
            <div
              class="col-12 col-md-5 col-lg-3 order-md-last text-truncate
              pt-little">
              <span class="small text-muted letter-space-1">
                {translate('exam_data.conducted_at')}
              </span>
              <span class="small text-muted d-md-none">:</span>
              <br class="d-none d-md-block" />
              <span class="row-val" class:font-italic={!exam.conductedDt}>
                {dateAndTime(exam.conductedDt)}
              </span>
            </div>

            <!-- Scanner Model
            <div class="col-12 col-md d-md-none d-lg-block text-truncate pt-little">
              <span class="small text-muted letter-space-1">
                {translate('exam_data.scanner_model')}
              </span>
              <span class="small text-muted d-md-none">:</span>
              <br class="d-none d-md-block" />
              <span class="row-val" class:font-italic={!exam.scannerModel}>
                {exam.scannerModel || translate('unknown')}
              </span>
            </div> -->

            <!-- Field Strength
            <div class="col-12 col-md d-md-none d-lg-block text-truncate pt-little">
              <span class="small text-muted letter-space-1">
                {translate('exam_data.scanner_field_strength')}
              </span>
              <span class="small text-muted d-md-none">:</span>
              <br class="d-none d-md-block" />
              <span class="row-val" class:font-italic={!exam.fieldStrength}>
                {exam.fieldStrength || translate('unknown')}
              </span>
            </div> -->

            <!-- Comments -->
            <a
              class="col-12 col-md-auto col-lg-3 text-truncate unlink pt-little"
              href={`${href}/comments`}>
              <span class="small text-muted letter-space-1">
                {translate('multiviewer.comment', 99)}
              </span>
              <span class="small text-muted d-md-none">:</span>
              <br class="d-none d-md-block" />
              <span class="row-val">{comments.length}</span>
            </a>

            <!-- Indication -->
            <div class="col-12 col-md order-md-first text-truncate pt-little">
              <span class="small text-muted letter-space-1">
                {translate('exam_data.indication_description')}
              </span>
              <span class="small text-muted d-md-none">:</span>
              <br class="d-none d-md-block" />
              <span class="row-val" class:font-italic={!exam.descriptionUser}>
                {exam.descriptionUser || translate('exam_data.indication_missing')}
              </span>
            </div>
          </div>
        </div>

        <!-- Tags & Open All -->
        <div
          class="col-12 d-flex align-items-start justify-content-between mt-2
          mt-sm-0 mt-md-2 position-unset">
          <div>
            <span class="badge badge-exams mb-2">
              {translate('modules.exam', 1)}
            </span>
            {#each exam.seriesSet.filter(isImagingSequence) as { seriesId, descriptionUser } (seriesId)}
              <span
                class="ml-2 mb-2 exam-list-scan-badge {getBadgeClassName(descriptionUser)}"
                title={descriptionUser.replace(/_/g, ' ')}>
                {descriptionUser.split('_')[0]}
              </span>
            {/each}
          </div>

          <!-- Open All -->
          {#if openAllHref}
            <a
              class="open-all-btn | btn btn-sm btn-link position-absolute top-0
              right-0 mr-sm--2 ws-nowrap"
              href={openAllHref}>
              {translate('multiviewer.open_all')}
            </a>
          {/if}
        </div>
      </div>
    </div>
  </a>
{/if}
