<script context="module">
  import { getAuthHeader } from "../helpers/apollo";

  export async function httpCreateScan({
    file,
    conductedDt,
    examId,
    descriptionTxt,
  }) {
    // Construct the upload body
    const body = new FormData();
    body.append("data", file);
    body.set("frame_count", 1);
    body.set("capture_dt", conductedDt);
    body.set("sequence_metadata", null);
    body.set("device", `3rd Party : ${file.type.split("/")[1]}`);
    body.set("content_type", "application/nifti1");
    body.set("series_type", "MRI");
    body.set("exam", examId);
    body.set("description_user", descriptionTxt);

    return fetch("https://app-4534.on-aptible.com/api/v1/series/", {
      body,
      method: "POST",
      headers: {
        ...getAuthHeader(),
      },
    });
  }
</script>

<script>
  // Import our external dependencies
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { createEventDispatcher } from "svelte";
  import { fetchTranslator, makeErrorHandler } from "../helpers/fetch";
  import PhaseButton from "../components/phase-button.svelte";
  import DragNDrop from "../components/drag-n-drop.svelte";

  // External Props
  export let exam = "";
  export let closeUrl = "";

  // Internals
  const dispatch = createEventDispatcher();

  // Form Fields
  let files = [];
  let descriptionTxt = "";
  // let sequenceName = "";
  // let sequenceParameters = "";
  // let contrastType = "";
  // let duration = null;

  // Submission & Async Handling
  let scan = null;
  let isUploading = false;
  const httpErrorHandler = makeErrorHandler(err => {
    errorTxt = err;
    isUploading = false;
  });
  async function handleFormSubmit() {
    if (!validateForm()) return;

    isUploading = true;
    errorTxt = "";

    const scan = await httpCreateScan({
      descriptionTxt,
      file: files[0],
      conductedDt: exam.conductedDt,
      examId: exam.examId,
    })
      .then(fetchTranslator)
      .catch(httpErrorHandler);

    isUploading = false;
    dispatch("uploaded", scan);
  }

  // Form Validation
  function validateForm() {
    if (!files || !files.length)
      return !(errorTxt = translate("validation.file_required"));
    if (!descriptionTxt)
      return !(errorTxt = translate("validation.description_required"));
    return true;
  }

  // Form Reset
  function handleFormReset() {
    files = [];
    descriptionTxt = "";
    // sequenceName = sequenceParameters = contrastType = errorTxt = "";
    // duration = scan = null;
    scan = null;
  }

  // Error Handling
  let errorTxt = "";
  let errorEl;
  $: if (errorEl) {
    errorEl.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

.add-scan-form {
  border-top-color: #80417d !important;
  padding-bottom: 2rem; }
  .add-scan-form :global(.upload-box).hover, .add-scan-form :global(.upload-box):hover {
    color: #80417d !important; }

/*# sourceMappingURL=x.map */</style>

<!-- TODO: Handle the Create Scan Form after creation is successful. -->
<section
  class="add-scan-form | pt-4 px-4 border rounded position-relative shadow"
  data-component="add-scan-form">

  <!-- Title -->
  <div class="title-bgblock left-0 top-0 position-absolute w-100" />
  <h3 class="text-truncate mt-3 mb-4 position-relative">
    <span>{translate('form_labels.create_scan')}</span>
  </h3>

  <!-- Close Button -->
  <a
    class="close position-absolute top-0 right-0 p-3 m-1"
    aria-label={translate('form_labels.close')}
    href={closeUrl}>
    <span aria-hidden="true">×</span>
  </a>

  <!-- Form -->
  <form on:submit|preventDefault={handleFormSubmit}>

    <!-- File Selector -->
    <DragNDrop bind:value={files} accept=".nii, .nifti, .dcm, .dicom" />

    <!-- File Name(s) -->
    <div class="form-group">
      <label for="ASF-Name">{translate('form_labels.file_name')}</label>
      <input
        type="text"
        class="form-control"
        id="ASF-Name"
        value={files && files.length ? files[0].name : ''}
        disabled />
    </div>

    <fieldset class="container-fluid px-0" disabled={isUploading || scan}>
      <div class="form-row">

        <!-- Description -->
        <div class="col form-group">
          <label for="InputDescription" class="text-truncate">
            {translate('form_labels.description')}
          </label>
          <input
            type="text"
            id="InputDescription"
            class="form-control"
            bind:value={descriptionTxt} />
        </div>

        <!-- Sequence Name
        <div class="col-sm-6 col-md-12 col-lg-6 form-group">
          <label for="InputSqName" class="text-truncate">
            {translate('exam_data.sequence_name')}
          </label>
          <input
            type="text"
            id="InputSqName"
            class="form-control"
            bind:value={sequenceName} />
        </div> -->

        <!-- Sequence Parameters
        <div class="col-sm-6 col-md-12 col-lg-6 form-group">
          <label for="InputSqParams" class="text-truncate">
            {translate('exam_data.sequence_parameters')}
          </label>
          <input
            type="text"
            id="InputSqParams"
            class="form-control"
            bind:value={sequenceParameters} />
        </div> -->

        <!-- Contrast Type
        <div class="col-sm-6 col-md-12 col-lg-6 form-group">
          <label for="InputContrast" class="text-truncate">
            {translate('exam_data.contrast_type')}
          </label>
          <input
            type="text"
            id="InputContrast"
            class="form-control"
            bind:value={contrastType} />
        </div> -->

        <!-- Duration
        <div class="col-sm-6 col-md-12 col-lg-6 form-group">
          <label for="InputDuration" class="text-truncate">
            {translate('form_labels.duration')}
          </label>
          <input
            type="number"
            step="0.25"
            min="0"
            id="InputDuration"
            class="form-control"
            bind:value={duration} />
        </div> -->
      </div>
    </fieldset>

    <PhaseButton
      className="btn-block"
      startClassName="btn-scans"
      successTxt={translate('form_labels.create_scan_success')}
      isInFlight={isUploading}
      isSuccessful={scan}>
      <span>{translate('form_labels.create_scan_submit')}</span>
    </PhaseButton>
    {#if scan}
      <div class="text-center h-0 | animated fadeIn">
        <button
          on:click={handleFormReset}
          type="button"
          class="btn btn-sm btn-link text-truncate">
          <span>{translate('form_labels.create_another')}</span>
        </button>
      </div>
    {/if}

    <!-- Error Message -->
    {#if errorTxt}
      <div
        class="animated fadeIn faster alert alert-danger text-monospace mt-2
        w-100"
        role="alert"
        bind:this={errorEl}>
        <h4 class="alert-heading">{translate('support.error_has_occurred')}</h4>
        <p>{errorTxt}</p>
      </div>
    {/if}
  </form>
</section>
