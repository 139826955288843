<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { dateInWords, isImagingSequence } from "../helpers/formatters";
  import { getBadgeClassName } from "../exam-module/helpers";

  // External Props.
  export let exam = null;
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

.exam-list-item {
  transition: border-top-color 0.2s ease-out; }
  .exam-list-item:hover {
    text-decoration: none;
    color: inherit;
    border-top-color: #4daf7c !important; }

@media (min-width: 576px) {
  .open-all-btn {
    position: relative !important; } }

/*# sourceMappingURL=x.map */</style>

{#if exam}
  <a
    class="exam-list-item | unlink d-block mb-3 py-2 border rounded
    container-fluid position-relative"
    href="#/exams/details/{exam.examId}">
    <div class="exam-list-item-row row">
      <!-- Conducted At Column -->
      <div class="col-sm-6 col-md-4 col-lg-4">
        <span class="d-block d-sm-none small letter-space-1 text-muted">
          {translate('exam_data.conducted_at')}
          <br />
        </span>
        <h5 class="text-truncate mb-1">{dateInWords(exam.conductedDt)}</h5>
      </div>

      <!-- Indication Column -->
      <div class="col-sm-6 col-md-4 col-lg-5 col-xl-4 mt-2 mt-sm-0">
        <span class="small letter-space-1 text-muted">
          {translate('exam_data.indication_description')}
        </span>
        <br />
        <span>{exam.descriptionUser || ''}</span>
      </div>
    </div>

    <!-- Captures / Scans / Tags -->
    <div class="mt-2 d-flex align-items-end ml--2">
      <div class="flex-grow-1">
        <span class="badge badge-exams ml-2">
          {translate('modules.exam', 1)}
        </span>
        {#each exam.seriesSet.filter(isImagingSequence) as { seriesId, descriptionUser } (seriesId)}
          <span
            class="ml-2 exam-list-scan-badge {getBadgeClassName(descriptionUser)}"
            title={descriptionUser.replace(/_/g, ' ')}>
            {descriptionUser.split('_')[0]}
          </span>
        {/each}
      </div>
      <div>
        <a
          class="open-all-btn | btn btn-sm btn-link text-truncate
          position-absolute top-0 right-0 pr-3 pt-2 mr-sm--3 mb-sm--2"
          href="#/multiviewer?scans={exam.seriesSet
            .filter(scan => isImagingSequence(scan))
            .map(({ seriesId }) => seriesId)
            .toString()}">
          {translate('multiviewer.open_all')}
        </a>
      </div>
    </div>
  </a>
{/if}
