<script>
  // Include our external dependencies.
  import { translate } from "../helpers/i18n";
  import Modal, { closeUrl } from "../components/modal.svelte";

  // External Props
  export let scan = false;

  function getDicomDescription(key) {
    const group = key.substring(0, 4).toUpperCase();
    const element = key.substring(4).toUpperCase();

    const tag = `(${group},${element})`;
    const description = tag || "Not Found";

    return description;
  }
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

.scan-details-modal :global(.modal-content) {
  border-top-color: #9a82f6 !important; }

/*# sourceMappingURL=x.map */</style>

<div class="scan-details-modal" data-component="scan-details-modal">
  <Modal
    title={translate('multiviewer.scan_details')}
    className="modal-xl modal-dialog-scrollable">
    <div class="modal-body">
      {#if !scan || !scan.sequenceMetadata}
        <div class="text-center py-5 px-3">
          <em>No Data</em>
        </div>
      {:else}
        <table class="table table-dark">
          <thead>
            <tr>
              <th scope="col">{translate('identifier')}</th>
              <th scope="col">{translate('scan_data.metadata_vr')}</th>
              <th scope="col">{translate('scan_data.metadata_value')}</th>
            </tr>
          </thead>
          <tbody>
            {#each Object.entries(scan.sequenceMetadata) as [key, { vr, value }], i (key)}
              <tr>
                <th scope="row">
                  {getDicomDescription(key)}
                  <br />
                  <em class="small text-muted">{key}</em>
                </th>
                <td>{vr}</td>
                <td class="small">
                  <pre>
                    <code>{JSON.stringify(value, null, 2)}</code>
                  </pre>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    </div>
    <div class="modal-footer">
      <a href={$closeUrl} class="btn btn-primary" data-dismiss="modal">Done</a>
    </div>
  </Modal>
</div>
