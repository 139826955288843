<script>
  // Import our external dependencies.
  import { onDestroy } from "svelte";
  import { fade } from "svelte/transition";
  import {
    translate,
    loadLocale,
    locale,
    languages,
    distanceInWords,
  } from "i18n"; //eslint-disable-line import/no-unresolved
  import user, { exit } from "../stores/user";
  import { route0 } from "../stores/router";
  import notifications, { dismiss } from "../stores/notifications";
  import now from "../stores/now";
  import { trackAction } from "../helpers/telemetry";
  import { supportEmailHref } from "./preauth.svelte";

  // External Props
  export let className = "";

  // Reactive collection of our top-level navigation entries.
  let activeAppModuleIdx = 0;
  const appModules = [
    {
      name: "exams",
      hed: translate("modules.exam", 99),
      icon: "fa-microscope",
    },
    {
      name: "scans",
      urlRoot: "multiviewer",
      hed: translate("modules.multiviewer", 99),
      icon: "fa-film",
      notInMenu: true,
    },
    {
      name: "patients",
      hed: translate("modules.patient", 99),
      icon: "fa-user-injured",
      id: "patient",
    },
    {
      name: "organizations",
      hed: translate("modules.organizations", 99),
      icon: "fa-clinic-medical",
      id: "organization",
    },
  ];
  onDestroy(
    route0.subscribe(function UpdateActiveModule(route0) {
      let idx = appModules.findIndex(
        ({ urlRoot, name }) => urlRoot === route0 || name === route0
      );
      if (idx === -1) idx = 0;
      activeAppModuleIdx = idx;
    })
  );

  // Handle language selection
  function handleLanguageChange(event) {
    const { value } = event.target;
    trackAction("support", `language change - ${value}`);
    loadLocale(value);
  }

  // Keep the user menu open when the language selector is clicked.
  function keepMenuOpen(event) {
    event.stopPropagation();
    return event;
  }

  // Trigger an animation when we receive a new notification.
  let notificationCount = 0;
  let notifyThem = false;
  let notifyAnimTimer = null;
  const notification_unsubscribe = notifications.subscribe($notifications => {
    // Trigger the animation in the DOM.
    if (notificationCount < $notifications.length) {
      notifyThem = true;
      clearTimeout(notifyAnimTimer);
      notifyAnimTimer = setTimeout(function RemoveAnimation() {
        notifyThem = false;
      }, 2500);
    }

    // Update the counter to detect changes.
    notificationCount = $notifications.length;
  });

  // Listener and Timer Cleanup
  onDestroy(function RemoveSubscriptionsAndTimers() {
    notification_unsubscribe();
    clearTimeout(notifyAnimTimer);
  });
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

.header {
  background-color: #191a1c;
  border-bottom: 1px solid #000; }

.home-link {
  width: 28px; }

.app-module-selector .dropdown-toggle {
  min-width: 180px; }
  .app-module-selector .dropdown-toggle:after {
    float: right;
    margin-top: 9px; }

.app-module-selector .fas {
  display: inline-block;
  margin-right: 0.5rem; }

.no-notifications,
.toast {
  opacity: 1;
  width: 280px;
  max-width: 100%; }

.toast.status-ok {
  border-top-color: #35A7FF; }
  .toast.status-ok .toast-icon {
    color: #35A7FF; }

.toast.status-warn {
  border-top-color: #FFE74C; }
  .toast.status-warn .toast-icon {
    color: #FFE74C; }

.notification-dot {
  font-size: 0.6rem;
  left: 12%; }

.user-dropdown .dropdown-menu {
  width: 300px; }

.user-dropdown .policies {
  white-space: normal; }
  .user-dropdown .policies:hover {
    background-color: transparent; }

/*# sourceMappingURL=x.map */</style>

<header
  class="header | d-flex justify-content-between py-2 px-3 {className}"
  data-component="global-header">
  <div data-left class="d-flex align-items-center">

    <!-- Logo -->
    <a href="#/" class="d-inline-block home-link">
      <img src="images/h-logo.svg" alt={translate('app_name')} />
    </a>

    <!-- App Module Selector -->
    <nav class="app-module-selector dropdown d-inline-block ml-4">
      <button
        class="btn btn-outline-secondary dropdown-toggle text-left"
        type="button"
        id="GlobalModuleSelector"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <span class="text-light">
          <i
            class="fas fa-fw {appModules[activeAppModuleIdx].icon} text-{appModules[activeAppModuleIdx].name}" />
          {appModules[activeAppModuleIdx].hed}
        </span>
      </button>
      <div class="dropdown-menu" aria-labelledby="GlobalModuleSelector">
        {#each appModules as { name, urlRoot, hed, icon, notInMenu } (name)}
          {#if !notInMenu}
            <a class="dropdown-item px-3" href={`#/${urlRoot || name}`}>
              <i class="fas fa-fw {icon} text-{name}" />
              {hed}
            </a>
          {/if}
        {/each}
      </div>
    </nav>
  </div>
  <div data-right class="d-flex align-items-center">

    <!-- Notifications -->
    <div class="notifications dropdown d-inline-block">

      <!-- Open/Close Button -->
      <button
        class="unbutton position-relative"
        type="button"
        id="GlobalNotificationMenu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i
          class="far fa-lg fa-bell animated faster delay-1s"
          class:swing={notifyThem} />
        {#if $notifications.length}
          <i
            class="text-info notification-dot fas fa-dot-circle
            position-absolute top-0 | animated fadeIn"
            out:fade />
        {/if}
      </button>

      <!-- Menu -->
      <div
        class="dropdown-menu dropdown-menu-right px-2"
        aria-labelledby="GlobalNotificationMenu"
        on:click|stopPropagation>
        {#if !$notifications || !$notifications.length}
          <div class="p-3 no-notifications text-center">
            <em>{translate('notifications.none')}</em>
            <span>👍</span>
            <br />
          </div>
        {:else}
          <!-- Loop for notifications -->
          {#each $notifications as { uuid, status, hed_key, dek_key, led_key, icon, at, handleLedClick }, i (uuid)}
            <div
              class="toast status-{status}"
              role="alert"
              aria-live="assertive"
              aria-atomic="true">
              <div class="toast-header pt-2">
                <i class="toast-icon mr-3 {icon}" />
                <div class="mr-auto">
                  <strong class="d-block">{translate(hed_key)}</strong>
                  <small class="d-block mt--1 text-muted">
                    {distanceInWords(at, $now)} {translate('ago')}
                  </small>
                </div>
                <button
                  type="button"
                  class="ml-2 mb-1 close"
                  data-dismiss="toast"
                  aria-label={translate('form_labels.close')}
                  on:click={e => dismiss(uuid)}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="toast-body">
                <span>{translate(dek_key)}</span>
                {#if led_key && handleLedClick}
                  <a
                    href="#/update-ui"
                    on:click|preventDefault={handleLedClick}>
                    <span>{translate(led_key)}</span>
                  </a>
                {/if}
              </div>
            </div>
          {/each}
        {/if}
      </div>
    </div>

    <!-- User Menu -->
    <div class="user-dropdown dropdown d-inline-block">

      <!-- Open/Close Button -->
      <button
        class="unbutton pr-0"
        type="button"
        id="GlobalUserMenu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i class="fas fa-2x fa-user-circle" />
      </button>

      <!-- Menu -->
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="GlobalUserMenu">
        <label for="GlobalLanguageSelector">
          <h6 class="dropdown-header pl-3">
            {translate('label_chooose_language')}
          </h6>
        </label>
        <div class="px-3">
          <select
            class="form-control custom-select"
            id="GlobalLanguageSelector"
            value={locale}
            on:change={handleLanguageChange}
            on:click={keepMenuOpen}>
            <option value={null}>Choose a Language</option>
            {#if languages}
              {#each languages as { name, id }, i (id)}
                <option value={id}>{name}</option>
              {/each}
            {/if}
          </select>
        </div>

        <!-- Log In/Out -->
        <div class="dropdown-divider" />
        {#if $user && $user.userId}
          <h6 class="dropdown-header text-truncate">{$user.name}</h6>
          <a href="#/" class="dropdown-item" on:click={exit}>
            <span>
              <i class="mr-1 fas fa-fw fa-sign-out-alt" />
              {translate('form_labels.log_out')}
            </span>
          </a>
        {:else}
          <a class="dropdown-item unlink" href="#/login">
            <span>
              <i class="mr-1 fas fa-fw fa-sign-out-alt" />
              {translate('form_labels.log_in')}
            </span>
          </a>
        {/if}

        <!-- Report an Issue -->
        <a class="dropdown-item" href={supportEmailHref}>
          <span>
            <i class="mr-1 fas fa-fw fa-bug" />
            {translate('form_labels.report_issue')}
          </span>
        </a>

        <!-- Discord / Community -->
        <a class="dropdown-item unlink" href="https://discord.gg/jdB8msJ">
          <span>
            <i class="mr-1 fab fa-fw fa-discord" />
            Community Chat
          </span>
        </a>

        <!-- About -->
        <a class="dropdown-item unlink" href="#/about">
          <span>
            <i class="mr-1 fas fa-fw fa-address-card" />
            {translate('about.dek')}
          </span>
        </a>

        <!-- Legal & Policies -->
        <div class="dropdown-divider" />
        <div class="policies dropdown-item small text-muted py-0">
          <small>
            {@html translate('policies')}
          </small>
        </div>
      </div>
    </div>
  </div>
</header>
