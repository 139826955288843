<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { onDestroy } from "svelte";
  import { version } from "../../../package.json";
  import { route1 } from "../stores/router";
  import { getServiceWorker } from "../helpers/service-worker";
  import { trackPageView, trackAction } from "../helpers/telemetry";

  function getAdditionalPages() {
    return [
      {
        name: translate("about.log_in"),
        url: "#/login",
      },
      {
        name: translate("form_labels.create_account"),
        url: "#/create-account",
      },
    ];
  }

  const built_with = [
    {
      name: "Progressive Web App",
      icon: "images/lib-logos/pwa.svg",
      url: "https://developers.google.com/web/progressive-web-apps",
    },
    {
      name: "Svelte",
      icon: "images/lib-logos/svelte.svg",
      url: "https://svelte.dev/",
    },
    {
      name: "Bootstrap",
      icon: "images/lib-logos/bootstrap.svg",
      url: "https://www.getbootstrap.com/",
    },
    {
      name: "Apollo",
      icon: "images/lib-logos/apollo-100.png",
      icon_2x: "images/lib-logos/apollo-200.png",
      url: "https://www.apollographql.com/",
    },
    {
      name: "GraphQL",
      icon: "images/lib-logos/graphql.svg",
      url: "https://graphql.org/",
    },
    {
      name: "jQuery",
      icon: "images/lib-logos/jquery.svg",
      url: "https://jquery.com/",
    },
    {
      name: "WebGL",
      icon: "images/lib-logos/webgl.svg",
      url: "https://www.khronos.org/webgl/",
    },
    {
      name: "Workbox",
      icon: "images/lib-logos/workbox-96.png",
      icon_2x: "images/lib-logos/workbox-192.png",
      url: "https://developers.google.com/web/tools/workbox/",
    },
    {
      name: "Rollup",
      icon: "images/lib-logos/rollup.svg",
      url: "https://rollupjs.org/guide/en/",
    },
    {
      name: "Babel",
      icon: "images/lib-logos/babel.svg",
      url: "https://babeljs.io/",
    },
    {
      name: "Jest",
      icon: "images/lib-logos/jest.svg",
      url: "https://jestjs.io/",
    },
    {
      name: "ESLint",
      icon: "images/lib-logos/eslint.svg",
      url: "https://eslint.org/",
    },
  ];

  /* eslint-disable no-alert, no-console */
  async function handleClearCacheClick() {
    // If their system doesn't support service workers, let them know the app was never installed.
    if (!window.navigator || !navigator.serviceWorker)
      return window.alert(
        `🧐 Your browser does not support service workers. The app was never installed.`
      );

    // Double check that they mean to do this.
    const theyAreSure = window.confirm(
      `This will disable offline functionality until you reload the app from our servers. Please ensure you have internet connectivity before proceeding.`
    );
    if (!theyAreSure) return false;

    trackAction("support", "uninstall");

    // Clear all the caches.
    const serviceWorker = getServiceWorker();
    if (serviceWorker) {
      await serviceWorker.messageSW({ type: "CLEAR_CACHE" });
    }

    // Uninstall and alert them of the succeess.
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => registration.unregister());
      window.alert(
        `The app was uninstalled. If you reload the page, it will re-install the latest version from the world wide web.`
      );
    });
  }
  /* eslint-enable no-alert, no-console */

  // Analytics / Telemetry
  let isDestroyed = false;
  onDestroy(() => (isDestroyed = true));
  $: telemetry($route1);
  function telemetry($route1) {
    setTimeout(() => {
      if (isDestroyed) return false;
      const page = $route1 ? `about/${$route1}` : "about";
      trackPageView(page);
    });
  }
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

.summary,
.overline-row:hover {
  border-top: 1px solid #0675bc !important; }

.overline-row {
  transition: border-top-color 0.2s ease-out; }

.secondary-nav .nav-link {
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 200ms ease-out; }
  .secondary-nav .nav-link.active {
    border-bottom-color: #0675bc; }

.dep-logo {
  max-height: 100px;
  max-width: 100%; }

/*# sourceMappingURL=x.map */</style>

<section class="container-fluid fade-in pt-4 pb-3" data-component="about">
  <div class="row">

    <!-- Summary Panel -->
    <div class="col-md-5 col-xl-4 | pb-4">
      <section class="summary | p-4 border rounded position-relative shadow">
        <div
          class="title-bgblock left-0 top-0 position-absolute w-100 underlay" />
        <h1>{translate('app_name')}</h1>
        <span class="badge badge-primary mr-2">{translate('about.hed')}</span>

        <div class="details-list | mt-4 mt-md-5">
          <small class="text-muted text-capitalize letter-space-1 d-block mb-2">
            {translate('overview', 1)}
          </small>
          <div class="row d-flex justify-content-between py-1">
            <span class="col-auto text-capitalize">{translate('version')}</span>
            <span class="col text-right text-truncate">{version}</span>
          </div>
          <div class="row d-flex justify-content-between py-1">
            <span class="col-auto text-capitalize">
              {translate('time.updated_at')}
            </span>
            <span class="col text-right text-truncate">
              {translate('not_available_short')}
            </span>
          </div>
          <div class="row d-flex justify-content-between py-1">
            <span class="col-auto text-capitalize">
              {translate('time.screen')}
            </span>
            <span class="col text-right text-truncate">
              {translate('not_available_short')}
            </span>
          </div>
        </div>

        <div class="details-list | mt-3">
          <small class="text-muted text-capitalize letter-space-1 d-block mb-2">
            {translate('other_pages')}
          </small>
          {#each getAdditionalPages() as { name, url } (url)}
            <div
              class="row d-flex justify-content-between py-1 position-relative">
              <span class="col-auto text-capitalize">{name}</span>
              <a class="col text-right text-truncate stretched-link" href={url}>
                {url}
              </a>
            </div>
          {/each}
        </div>

        <div class="mt-4">
          <button
            type="button"
            class="btn btn-sm btn-outline-warning mr-3"
            on:click={handleClearCacheClick}>
            {translate('about.uninstall')}
            <i class="fas fa-trash small ml-2" />
          </button>
          <button
            type="button"
            class="btn btn-sm btn-outline-info"
            on:click={() => {
              trackAction('support', 'love me');
              alert(`${translate('about.love_you')} 😘`);
            }}>
            {translate('about.love_me')}
            <i class="fas fa-heart small ml-2" />
          </button>
        </div>
      </section>

    </div>

    <!-- Sub Navigated Section -->
    <div class="col-md-7 col-xl-8">

      <!-- Sub Navigation -->
      <nav class="nav secondary-nav pl-3 border-bottom mb-3">
        <a
          class="nav-link unlink"
          class:active={!$route1 || $route1 === 'how-to'}
          href="#/about/how-to">
          {translate('about.how_to')}
        </a>
        <a
          class="nav-link unlink"
          class:active={$route1 === 'under-the-hood'}
          href="#/about/under-the-hood">
          {translate('about.under_hood')}
        </a>
      </nav>

      <!-- Sub Routing -->
      <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  --->

      <!-- How To -->
      <div
        class="pt-4 px-4 border rounded position-relative shadow"
        class:d-none={$route1 && $route1 !== 'how-to'}>
        <div
          class="title-bgblock left-0 top-0 position-absolute w-100 underlay" />
        <h3 class="my-3">Configure Scanner For Upload</h3>
        <ol>
          <li>
            Power On Scanner
            <br />
            <small class="text-muted">Will start a wifi hotspot</small>
          </li>
          <li>
            Connect to Scanner
            <br />
            <small class="text-muted">
              Use a tablet, phone, or PC to connect to the device's hotspot
            </small>
          </li>
          <li>
            Open Scanner App
            <br />
            <small class="text-muted">
              On the connected device, open a web browser and navigate to the
              device's interface at http://xxx.xxx.xx.xxx:xxxx
            </small>
          </li>
          <li>
            Unencrypt Device
            <br />
            <small class="text-muted">
              Enter the shared password that was configured during initial setup
            </small>
          </li>
          <li>
            Log Into App
            <br />
            <small class="text-muted">
              Enter your personal username and private password to log into the
              application
            </small>
          </li>
          <li>
            Navigate to
            <em>Dev</em>
            Tab
            <br />
            <small class="text-muted">
              If this tab isn't visible, click the Hyperfine logo a few times
            </small>
          </li>
          <li>
            Open
            <em>Config</em>
            Panel
            <br />
            <small class="text-muted">
              Buttons on the left. Should be selected by default
            </small>
          </li>
          <li>
            Open
            <em>Metadata</em>
            Config
            <br />
            <small class="text-muted">
              Mauris et sapien in lacus feugiat malesuada
            </small>
          </li>
          <li>
            Expand
            <em>Cloud Upload</em>
            Settings
            <br />
            <small class="text-muted">
              console > Cloud Upload > Hyperfine Cloud Configuration
            </small>
          </li>
          <li>
            Activate
            <em>Upload to Hyperfine Cloud</em>
            <br />
            <small class="text-muted">
              Enter your credentials for
              <em>this</em>
              application
            </small>
          </li>
          <li>
            Click
            <em>Send</em>
            <br />
            <small class="text-muted">
              Your scans should become visible in the cloud as they're taken
            </small>
          </li>
        </ol>
        <img
          class="mw-100"
          src="images/how-to-connect-to-scanner.png"
          alt="Annotated Screenshot" />
      </div>

      <!-- Under The Hood -->
      <div class="px-3" class:d-none={$route1 !== 'under-the-hood'}>
        <p>{translate('about.give_thanks')}</p>
        <div class="container-fluid">
          <div class="row text-center">
            {#each built_with as { name, icon, icon_2x, url } (url)}
              <div
                class="col-6 col-lg-4 col-xl-3 col-xxl-2 mb-5 position-relative
                d-flex flex-column">
                <div class="d-flex flex-grow-1 mb-3">
                  {#if icon_2x}
                    <img
                      class="dep-logo my-auto mx-auto"
                      src={icon_2x}
                      alt="{name} Logo"
                      srcset="{icon}, {icon_2x} 2x" />
                  {:else}
                    <img
                      class="dep-logo my-auto mx-auto"
                      src={icon}
                      alt="{name} Logo" />
                  {/if}
                </div>
                <a class="h6 stretched-link" href={url}>{name}</a>
              </div>
            {/each}
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
