<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import intersection from "lodash-es/intersection";
  import uniq from "lodash-es/uniq";
  import makeUUID from "../helpers/uuid";
  import {
    shortDate,
    dateInWords,
    isImagingSequence,
  } from "../helpers/formatters";
  import { shortId } from "../exam-module/helpers";
  import { updateQueryParameters } from "../stores/router";
  import { scansToAdd } from "./scan-search-item.svelte";

  // External Props.
  export let exam = null;

  // Internal Props.
  const guid = makeUUID();

  // Function to (un)mark a scan for adding to viewer.
  function handleScanSelection(event, seriesId) {
    const isSelected = event.target.checked;
    const scansToAdd = isSelected
      ? [...$scansToAdd, seriesId]
      : $scansToAdd.filter(id => id !== seriesId);
    updateQueryParameters({ "scans-to-add": scansToAdd.toString() });
  }

  // Function to (un)mark ALL scans for adding to viewer.
  function handleAllScanSelection(event) {
    const isSelected = event.target.checked;
    const scanIds = exam.seriesSet.map(({ seriesId }) => seriesId);
    if (!scanIds || !scanIds.length) return false;
    const newScans = isSelected
      ? uniq([...$scansToAdd, scanIds])
      : $scansToAdd.filter(id => !scanIds.includes(id));
    updateQueryParameters({ "scans-to-add": newScans.toString() });
  }

  /*
    TODO: Create little cards for selected scans. Should have type and MRN.
    TODO: Add `Favorites`.
    TODO: Play with the modal styling. Try styling like the `Create Scan` card.
  */
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

.scan-search-item {
  transition: border-top-color 200ms ease-out;
  background-color: #212529; }
  .scan-search-item:hover {
    text-decoration: none;
    color: inherit;
    border-top-color: #4daf7c !important; }

:global(.exam-list-scan-badge) {
  border: 1px solid #80417d; }

.select-checkbox-cell {
  background-color: #1e2125; }

.cell-2 {
  width: 140px; }
  @media (min-width: 576px) {
    .cell-2 {
      width: 170px; } }

/*# sourceMappingURL=x.map */</style>

{#if exam}
  <div class="scan-search-item | rounded border mb-2">

    <label class="exam d-flex mb-0" for="select-exam-check-{guid}">

      <!-- Select All Scans Checkbox -->
      <div
        class="select-checkbox-cell px-2 py-2 d-flex align-items-center
        border-right">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="select-exam-check-{guid}"
            on:change={e => handleAllScanSelection(e)}
            checked={exam.seriesSet.length && intersection($scansToAdd, exam.seriesSet.map(({ seriesId }) => seriesId)).length === exam.seriesSet.length} />
          <label class="custom-control-label" for="select-exam-check-{guid}" />
        </div>
      </div>

      <!-- Date -->
      <div class="name-and-type px-3 py-2 flex-grow-1">
        <h5 class="font-weight-normal mb-1 d-none d-sm-block">
          {dateInWords(exam.conductedDt)}
        </h5>
        <h5 class="font-weight-normal mb-1 d-sm-none">
          {shortDate(exam.conductedDt)}
        </h5>
        <span class="badge badge-exams mr-2">
          {translate('modules.exam', 1)}
        </span>
      </div>

      <!-- ID -->
      <div class="cell-2 text-truncate px-3 py-2" title={exam.examId}>
        <small class="text-muted letter-space-1 text-capitalize">
          {translate('id')}
        </small>
        <span class="d-block mt-1 text-truncate">{shortId(exam.examId)}</span>
      </div>

      <!-- Field Strength
      <div class="field-strength | text-truncate px-3 py-2 d-none d-lg-block">
        <small class="text-muted letter-space-1 text-capitalize">
          {translate('exam_data.scanner_field_strength', 1)}
        </small>
        <br />
        <span class="font-italic">{translate('unknown', 1)}</span>
      </div> -->

      <!-- Updated At
      <div class="updated-at | text-truncate px-3 py-2 d-none d-sm-block">
        <small class="text-muted letter-space-1 text-capitalize">
          {translate('time.updated_at')}
        </small>
        <br />
        <span>{dateAndTime(exam.conductedDt)}</span>
      </div> -->
    </label>

    <!-- Loop through all of the Scans within this Exam. -->
    {#each exam.seriesSet.filter(isImagingSequence) as { seriesId, descriptionUser }, i (seriesId)}
      <label
        for="select-scan-checkbox-{seriesId}"
        class="scan-list-item d-flex border-top mb-0 {descriptionUser
          .split('_')
          .join(' ')
          .toLowerCase()}"
        class:selected={$scansToAdd.includes(seriesId)}>

        <!-- Select Checkbox -->
        <div
          class="select-checkbox-cell mb-0 px-2 py-2 d-flex align-items-center
          border-right">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="select-scan-checkbox-{seriesId}"
              on:change={e => handleScanSelection(e, seriesId)}
              checked={$scansToAdd.includes(seriesId)} />
            <label
              class="custom-control-label"
              for="select-scan-checkbox-{seriesId}" />
          </div>
        </div>

        <!-- Name -->
        <div class="name-and-type px-3 py-2 flex-grow-1">
          <h6 class="font-weight-normal mb-0">
            {descriptionUser.replace(/_/g, ' ')}
          </h6>
        </div>
      </label>
    {/each}
  </div>
{/if}
