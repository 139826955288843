<script>
  // Include our external depednencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import filter from "lodash-es/filter";

  // Props
  export let className = "";
  export let value = [];
  export let multiple = false;
  export let accept = "";

  // Supported file extensions - computed
  $: supportedExtensions = accept ? accept.replace(/ /g, "").split(",") : [];

  // Drag DOM Handlers.
  let isFileHovering = false;
  function handleFileDrop(event) {
    handleFileSelect(event.dataTransfer.files);
    isFileHovering = false;
  }
  function handleDragEnter(event) {
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.dropEffect = "move";
    isFileHovering = true;
  }

  // File Selection
  let fileSelectEl;
  function handleFileSelect(filesIn) {
    // Filter out extensions that we don't support.
    const files = filter(
      filesIn.length ? filesIn : fileSelectEl.files,
      file => {
        return (
          file &&
          file.name &&
          supportedExtensions.find(ext => file.name.indexOf(ext) !== -1)
        );
      }
    );
    value = files;
  }
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

.upload-box {
  border: 2px dashed #6c757d;
  height: 4rem;
  cursor: pointer;
  transition: all 0.2s ease-out; }
  .upload-box.hovering, .upload-box:hover {
    border-color: currentColor;
    color: #4daf7c !important; }

/*# sourceMappingURL=x.map */</style>

<div class={className} data-component="drag-n-drop">
  <label
    class="upload-box text-muted rounded mb-0 p-3 d-flex align-items-center"
    for="ScanFileUploader"
    class:hovering={isFileHovering}
    on:dragenter={handleDragEnter}
    on:dragleave={() => (isFileHovering = false)}
    ondragover="return false"
    on:drop|preventDefault={handleFileDrop}>
    <span class="mx-auto">{translate('form_labels.drop_to_upload')}</span>
  </label>
  <input
    type="file"
    class="hidden-file-uploader h-0 w-0"
    id="ScanFileUploader"
    {accept}
    {multiple}
    on:change={handleFileSelect}
    bind:this={fileSelectEl} />
</div>
