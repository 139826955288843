<script>
  // Include our external dependencies.
  import { translate, distanceInWords, parseDate } from "i18n"; //eslint-disable-line import/no-unresolved
  import user from "../../stores/user";
  import now from "../../stores/now";
  import { getInitials, getTags } from "../../helpers/formatters";

  // Props
  export let className = "";
  export let comments = [];
  export let scanId = "";

  // Link to create first comment.
  $: createCommentLink = `#/multiviewer?scans=${scanId}&comments=new`;
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

.comment {
  transition: border-top-color 0.2s ease-out; }
  .comment:hover {
    text-decoration: none;
    color: inherit;
    border-top-color: #0675bc !important; }

/*# sourceMappingURL=x.map */</style>

<section class={className} data-component="analysis">

  <!-- Empty State -->
  {#if !comments || !comments.length}
    <div
      class="border-dashed p-5 text-center text-muted border border-secondary
      rounded">
      <a href={createCommentLink}>
        <h4 class="mb-0">{translate('multiviewer.no_comments')}</h4>
      </a>
    </div>

    <!-- List of Comments -->
  {:else}
    {#each comments as { id, seriesId, annotations: { message, points, createdAt, author } } (id)}
      <a
        class="comment | d-block border rounded my-3 position-relative unlink"
        href={`#/multiviewer?scans=${seriesId}&comments=${id}`}>
        <div class="clearfix p-3">
          {#if author}
            <figure
              class="avatar | rounded-circle bg-primary float-right ml-2 mb-1
              overflow-hidden text-truncate text-center text-uppercase"
              title={author.name}>
              <figcaption>{getInitials(author.name)}</figcaption>
            </figure>
          {/if}
          <h6 class="text-truncate">
            {#if author}
              {#if author.id === $user.id}
                {translate('you')}
              {:else}{author.name}{/if}
            {/if}
            {#if createdAt}
              <small class="text-muted ml-1">
                {distanceInWords(parseDate(createdAt), $now)} {translate('ago')}
              </small>
            {/if}
          </h6>
          <p class="mb-0 message">{message}</p>
        </div>
        <div class="px-3">
          <span class="badge badge-primary mr-2 mb-2">
            {translate('multiviewer.comment', 1)}
          </span>
          {#each getTags(message) as tag}
            <span class="badge badge-primary badge-{tag} mr-2 mb-2">{tag}</span>
          {/each}
        </div>
      </a>
    {/each}
  {/if}
</section>
