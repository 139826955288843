<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { formatUrl } from "mcgregor-utils/url-utils";
  import { route, queryParameters } from "../stores/router";
  import ExamList from "./exam-list.svelte";
  import CreateExamForm from "./create-exam-form/create-exam-form.svelte";
  import { trackPageView } from "../helpers/telemetry";

  // Analytics
  trackPageView("exams-home");

  $: isCreateExam = $queryParameters["create-exam"];

  // Scroll form into view when opened.
  let formEl;
  $: if (formEl) {
    formEl.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  // When incremented, this will trigger a refresh of the Exam List.
  let triggerRefresh = 0;
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

.add-exam-btn {
  border-color: #6c757d; }
  .add-exam-btn:hover {
    border-color: #4daf7c; }

/*# sourceMappingURL=x.map */</style>

<section data-component="exam-home">
  <main class="p-3">
    <h1 class="mb-3">{translate('modules.exam', 99)}</h1>
    <ExamList {triggerRefresh}>
      {#if !isCreateExam}
        <a
          class="add-exam-btn btn btn-sm btn-block btn-outline-exams text-light
          mt-3"
          href={`#/${formatUrl({
            $route,
            $queryParameters,
            newQPs: { 'create-exam': true },
          })}`}>
          {translate('form_labels.create_exam')}
        </a>
      {:else}
        <div class="pt-3" bind:this={formEl}>
          <CreateExamForm on:exam-created={() => triggerRefresh++} />
        </div>
      {/if}
    </ExamList>
  </main>
</section>
