<script>
  // Import our external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { shortDate } from "../helpers/formatters";
  import { client } from "../helpers/apollo";
  import { renderFullName } from "../exam-module/helpers";
  import ExamList from "../exam-module/exam-list.svelte";
  import ExamListItem from "./patient-exam-item.svelte";
  import { trackPageView } from "../helpers/telemetry";
  import { GET_SUBJECT } from "./patients.graphql";

  // Analytics
  trackPageView("patient-details");

  // External Props
  export let examSubjectId = "";

  // Internal Props
  let subject = false;

  // Load Subject Details from the API
  $: (async function GetSubjectFromAPI(id) {
    subject = false;
    const resp = await client.query({
      query: GET_SUBJECT,
      variables: { examSubjectId: id },
    });
    subject = resp.data.subject;
  })(examSubjectId);

  // Computed Property : subjectName
  $: subjectName = renderFullName(subject ? subject.fullName : false);
</script>

<style type="text/scss">@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.eot");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Calibre-Regular";
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  src: url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff") format("woff"), url("https://hyperfine-web-fonts.s3.amazonaws.com/fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic; }

.summary {
  border-top-color: #FFD166 !important; }

.scan-list-item {
  transition: border-top-color 200ms ease-out;
  overflow: hidden; }
  .scan-list-item:hover, .scan-list-item.selected {
    text-decoration: none;
    color: inherit; }

.summary h1 {
  margin-top: 1px; }

/*# sourceMappingURL=x.map */</style>

<section data-component="patient-details" class="fade-in">

  <!-- Uninitialized - Loading -->
  {#if subject === false}
    <div
      class="d-flex justify-content-center align-items-center bg-spinner my-4">
      <div class="spinner-border" role="status">
        <span class="sr-only">{translate('loadingDotDotDot')}</span>
      </div>
    </div>

    <!-- Unauthorized or Can't find Subject -->
  {:else if !subject}
    <h3 class="my-5 text-center">{translate('patient_data.not_found')}</h3>

    <!-- Loaded Content -->
  {:else}
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item">
          <a href="#/patients">{translate('modules.patient', 99)}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {subject ? subjectName : subject.subjectId}
        </li>
      </ol>
    </nav>

    <div class="container-fluid fade-in">
      <div class="row">

        <!-- Summary Panel -->
        <div class="col-12 col-lg-5 col-xl-4 | pb-4">
          <section class="summary | p-4 border rounded position-relative">
            <div class="title-bgblock left-0 top-0 position-absolute w-100" />
            <a class="unlink position-relative" href={window.location.hash}>
              <h1 class="text-truncate" title={subjectName}>{subjectName}</h1>
            </a>
            <span class="badge badge-patients mr-2">
              {translate('modules.patient', 1)}
            </span>
            <div class="details-list | mt-5">
              <small
                class="text-muted text-capitalize letter-space-1 d-block mb-2">
                {translate('overview', 1)}
              </small>
              <div class="d-flex justify-content-between py-1">
                <span>
                  {translate('exam_data.medical_records_number_short', 1)}
                </span>
                <span>{subject.subjectId}</span>
              </div>
              <div class="d-flex justify-content-between py-1">
                <span>{translate('patient_data.dob', 1)}</span>
                <span>{shortDate(subject.birthDate)}</span>
              </div>
              <div class="d-flex justify-content-between py-1">
                <span>{translate('patient_data.gender', 1)}</span>
                <span>
                  {subject.sex || translate('patient_data.gender_unknown')}
                </span>
              </div>
            </div>
          </section>
        </div>

        <!-- Right Side -->
        <div class="col-12 col-lg-7 col-xl-8 | pb-4">
          <div class="details">
            <ExamList filter={subject.subjectId} {ExamListItem} />
          </div>
        </div>
      </div>
    </div>
  {/if}
</section>
